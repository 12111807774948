import { z } from "zod";

export const zEmptyStrToUndefined = z
  .string()
  .transform((str) => (str === "" ? undefined : str))
  .optional() as z.ZodType<string | undefined>;

export const zodEmptyStrToUndefined = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((arg) => {
    if (typeof arg === "string" && arg === "") {
      return undefined;
    }
    return arg;
  }, schema);

export const zStringOrDate = z
  .union([
    z.string().refine((val) => !isNaN(Date.parse(val)), {
      message: "Invalid date string",
    }),
    z.date(),
  ])
  .transform((val) => new Date(val));
